<template>
  <div id="serviceLogs">
    <div
      class="card-service-log"
      v-for="(item, index) in listagemActivity"
      :key="index"
      @click="redirectToLogCenter(item)"
    >
      <div class="id">ID: {{ item.id }}</div>
      <div class="pipeline">
        <div class="label">{{ $t('Dashboard.Logs.DateTime') }}</div>
        <div class="action">{{ getFormatedDate(item.created_at) }}</div>
      </div>
      <div class="activity">
        <div class="label">{{ $t('Dashboard.Logs.Message') }}</div>
        <div class="action">{{ item.message }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment';

  export default {
    data() {
      return {
        listagemActivity: []
      };
    },
    props: {
      listagem: {
        type: Array
      }
    },
    watch: {
      listagem: function (newVal) {
        this.listagemActivity = newVal.slice(0, 5);
      }
    },
    methods: {
      getFormatedDate(date) {
        if (date) {
          let language = localStorage.getItem('language');

          if (language == 'pt-BR') return moment(date).format('DD-MM-YYYY HH:mm');
          else return moment(date).format('MM-DD-YYYY HH:mm');
        }

        return '';
      },
      redirectToLogCenter(item) {
        this.$emit('redirect', { page: 'log-center', id: item.id, shir: true });
      }
    }
  };
</script>

<style lang="scss">
  #serviceLogs {
    .card-service-log {
      border-radius: 6px;
      border: 1px solid #eee;
      padding: 12px 8px;
      background: #fff;
      cursor: pointer;
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 11px !important;
      }

      &:hover {
        background: #974900;

        .pipeline,
        .activity {
          .label,
          .action {
            color: #fff;
          }
        }
      }

      .id {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        border-radius: 4px;
        background: #37474f;
        color: #fff;
      }

      .pipeline {
        padding-top: 6px;
      }

      .activity {
        padding-top: 4px;
      }

      .pipeline,
      .activity {
        .label,
        .action {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          color: #998f8a;
        }

        .action {
          font-weight: 600;
          color: #4c4541;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
</style>
